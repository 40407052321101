import React from 'react';
import queryString, { ParsedQuery } from 'query-string'
import BlogPostListingPage, { BlogPostTag } from '../../templates/blog-post-listing';
import { StaticQuery, graphql } from 'gatsby';
import _ from 'lodash'


type SearchPageProps = {
    tagsRawData: Array<{
        node: BlogPostTag;
    }>;
    location: Location;
    availablePrismicBlogs: Array<{
        node: {
            slug: string;
        }
    }>;
};

type SearchPageState = {
    tags: BlogPostTag[];
    queryParams: ParsedQuery;
    startedSearch: boolean;
    finishedSearch: boolean;
    allowedPrismicSlugs: any[];

    pageContext: {
        posts: any[] | null;
        pageIndex: number;
        totalPages: number;
    }
};

class SearchPage extends React.Component<SearchPageProps, SearchPageState> {

    constructor(props: SearchPageProps) {
        super(props);
        const { tagsRawData, location, availablePrismicBlogs } = props;
        const tags = tagsRawData.map(t => t.node);
        const queryParams = queryString.parse(location.search);

        const allowedPrismicSlugs: Array<string> = availablePrismicBlogs.map(t => t.node.slug);

        this.state = {
            tags, queryParams,
            startedSearch: false,
            finishedSearch: false,
            allowedPrismicSlugs,

            pageContext: {
                posts: null,
                pageIndex: 0,
                totalPages: 1,
            }
        }
    }

    loadData = async() => {
        try {
            if (!this.state.startedSearch || !this.state.finishedSearch) {
                this.setState({ startedSearch: true });
                const res = await fetch(`/api/blog-search/?q=${this.state.queryParams.q}`, {
                    method: 'GET',
                });

                const json = await res.json();
                
                const copiedPageContext = {
                    ...json.pageContext,
                    posts: (json.pageContext.posts).filter((post: {legacy: boolean; data: {slug: string}}) => {
                        if (post.legacy) {
                            return true;
                        }
                        return _.includes(this.state.allowedPrismicSlugs, post.data.slug);
                    }),
                }
                this.setState({
                    pageContext: copiedPageContext,
                });
            }
        } catch (e) {}

        this.setState({
            startedSearch: false,
            finishedSearch: true,
        });
    }

    componentWillMount() {
        this.loadData();
    }

    render() {
        const { tags, queryParams } = this.state;
        let pageIndex: number = 0;
        if (queryParams.page && (typeof queryParams.page == 'string' || typeof queryParams.page == 'number')) {
            pageIndex = parseInt(queryParams.page);
        }
        const pageContext = {
            ...this.state.pageContext,
            topLevelBlogData: {
                tags: tags,
            },
            search: true,
            currentSearch: queryParams.q,
        };
        
        return (
            <BlogPostListingPage location={this.props.location} pageContext={pageContext} />
        );
    }
}

const SearchPageStatic: React.FC<{ location: Location }> = ({ location }) => {
    return <StaticQuery query={graphql`
    {
        allPrismicBlogCategory {
          edges {
            node {
              data {
                name
                slug
                include_in_header
              }
            }
          }
        }
        allPrismicBlogPost(limit: 10000) {
          edges {
            node {
              data {
                slug
              }
            }
          }
        }
      }      
`} render={(result) => <SearchPage location={location} availablePrismicBlogs={result.allPrismicBlogPost.edges} tagsRawData={result.allPrismicBlogCategory.edges} />} />
}

export default SearchPageStatic;